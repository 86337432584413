import { useEffect } from 'react';
import { setExitUrl, useAppContext } from '../context/app-context';
import { getMonolithUrl } from '../utils';
import { useQueryParams } from './use-query-params';

export const useExitUrl = () => {
  const queryParams = useQueryParams();
  const { appDispatch } = useAppContext();
  const monolithUrl = getMonolithUrl();

  useEffect(() => {
    const skuReturn = queryParams.get('returnsku');
    const cartReturn = queryParams.get('fromCart');

    if (skuReturn) {
      const exitUrl = `${monolithUrl}/cards/${skuReturn}`;
      setExitUrl(appDispatch, exitUrl);
      return;
    }
    if (cartReturn) {
      const exitUrl = `${monolithUrl}/shopping-cart/`;
      setExitUrl(appDispatch, exitUrl);
      return;
    }
    if (window.document.referrer.includes(monolithUrl)) {
      setExitUrl(appDispatch, window.document.referrer);
      return;
    }
    setExitUrl(appDispatch, monolithUrl);
  }, [queryParams]);
};
