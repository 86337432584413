import { DeliveryModes } from '../components/address-form/address-form-types';
import { GetDefaultTextAreaOptions } from '../components/card-editor/card-editor-types';
import { AddressResponseData } from './addresses';
import { CardFace } from './card-face';
import { Envelope } from './envelope';
import { Font } from './font';
import { InformationDetails } from './information-details';

type Dimensions = {
  Width: number;
  Height: number;
};

type TemplateData = {
  Name: string;
  Dimensions: Dimensions;
  DefaultTextArea: GetDefaultTextAreaOptions;
  ParentDimensions: Dimensions;
  OpenOrientation: string;
  CardSize: string;
  CardFormat: string;
  Faces: CardFace[];
  CardType: string;
  IsFrontOfCardNotEditable: boolean;
  IsInsideOfCardNotEditable: boolean;
  IsVederePhoto?: boolean;
};

type Variables = {
  template_data: TemplateData;
};

type FontCollection = {
  default_size: number;
  default_color: string;
  fonts: Font[];
};

type Price = {
  /** Card quantity associated to a unitary price*/
  quantity: number;
  /** Price per card at selected quantity*/
  price: number;
};

type PriceBook = {
  price: number;
  price_tiers: Price[];
};

type Product = {
  product_id: string;
  template_id: string;
  product_name: string;
  is_available?: boolean;
  vendor_lead_time: number;
  envelope_color: string;
  thumbnail_url?: string;
  price_book?: PriceBook;
};

type ProjectTypeCode = 'S' | 'P' | 'D';

type resize_urls = {
  M: string;
  S: string;
};

export enum AssetTypes {
  FRONT_PANEL = 'F',
  INSIDE_PANEL = 'I',
  FRONT_PANEL_PREVIEW = 'P',
  BACK_PANEL = 'B',
  PDF_CARD = 'C',
  PDF_ENVELOPE = 'E',
}

type ProjectAsset = {
  asset_id: string;
  asset_type_code: AssetTypes;
  created_at: string;
  last_updated_at: string;
  resize_urls: resize_urls;
  url: string;
};

type InitializationData = {
  [key: string]: unknown;
  project_id: string;
  assets?: ProjectAsset[];
  account_id: string;
  addresses?: AddressResponseData[];
  name: string;
  product_id: string;
  scan_code: string;
  version: number;
  expiration_date: string;
  project_type_code: ProjectTypeCode;
  project_status_code: string;
  created_at: string;
  last_updated_at: string;
  variables: {
    template_data: TemplateData;
  };
  font_collection: FontCollection;
  product: Product;
  content?: {
    font_collection: FontCollection;
    product: Product;
    variables: Variables;
    content?: {
      name: string;
      reference_id: string;
      start_message: string;
      write_a_message_button_text: string;
      type_a_message_button_text: string;
      recipient_address_copy: string;
      sender_address_copy: string;
      priority_shipping_header: string;
      priority_shipping_copy: string;
      expedited_shipping_header: string;
      expedited_shipping_copy: string;
      informational_details: InformationDetails[];
    };
    envelope?: Envelope;
  };
  is_digital_fulfillment: boolean;
  is_draft: boolean;
  purchase_quantity: number;
};

type InitBody = {
  name: string;
  product_id: string;
  project_type_code: string;
  is_digital_fulfillment: boolean;
  is_draft: boolean;
  purchase_quantity: number;
  delivery_mode?: DeliveryModes;
  envelope_type?: string;
};

export type { InitializationData, TemplateData, ProjectTypeCode, InitBody, ProjectAsset };
