import React from 'react';
import classNames from 'classnames';
import { Button, ButtonModes, IconProps } from '@hallmark/web.core.buttons.button';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { LoadingButtonProps } from './loading-button-types';
import styles from './loading-button.module.scss';

export const LoadingButton = ({
  isLoading,
  loadingText,
  loadingColor,
  defaultColor,
  isIconSpinning = true,
  loadingIconName = IconNames.SyncSyncInactive,
  defaultText,
  testId = 'loading-button',
  click,
  addClass = '',
}: LoadingButtonProps): React.ReactElement => {
  const classes = classNames(
    styles['loading-button'],
    isIconSpinning && styles['loading-button--icon-spinning'],
    addClass,
  );

  const loadingIcon: IconProps = {
    name: loadingIconName,
    color: isLoading ? loadingColor : defaultColor,
    addClass: classNames(
      isLoading ? styles['loading-icon'] : styles['loading-icon--inactive'],
      isIconSpinning && styles['loading-icon--spinning'],
    ),
    size: 12,
  };

  return (
    <Button
      addClass={classes}
      mode={ButtonModes.TextLink}
      click={click}
      testId={testId}
      disabled={isLoading}
      color={isLoading ? loadingColor : defaultColor}
      startIcon={loadingIcon}
    >
      {isLoading ? loadingText : defaultText}
    </Button>
  );
};
