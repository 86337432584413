import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { ObjectOrder } from '@hallmark/web.page-components.print-on-demand.widgets.object-order';
import { usePositionTab } from '../../drawer-container/hooks/use-position-tab';
import styles from '../font-drawer.module.scss';

export const PositionTab = (): React.ReactElement => {
  const { t } = useTranslation();
  const { handleBringToFrontClick, handleSendToBackClick } = usePositionTab();

  return (
    <div data-testid="position-tab">
      <div className={styles['position-text-layer-label']}>
        <Typography variant={TypographyVariants.FieldLabel}>{t('fontDrawer.positionTextLayer')}</Typography>
        <ObjectOrder
          onBringToFront={handleBringToFrontClick}
          onSendToBack={handleSendToBackClick}
          bringToFrontLabel={t('fontDrawer.bringToFront')}
          sendToBackLabel={t('fontDrawer.sendToBack')}
        />
      </div>
    </div>
  );
};
