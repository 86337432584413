import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { Button, ButtonModes, ButtonTypes } from '@hallmark/web.core.buttons.button';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { TextField } from '@hallmark/web.core.forms.text-field';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useAnalyticsContext } from '../../context/analytics-context';
import { setIsToasterOpen, setProductQuantity, useAppContext } from '../../context/app-context';
import { useInitializationDataContext } from '../../context/data-context';
import { EnvelopeType } from '../../global-types/envelope';
import { useFeatureFlags, useQueryParams } from '../../hooks';
import useAddressSelection from '../../hooks/use-address-selection';
import { config } from '../../regional-config';
import { updateProductQuantity } from '../../services/customization';
import { useEnvelopeSelection } from '../../views/select-envelope/hooks/use-envelope-selection';
import { SummaryDrawer } from '../card-controls/summary-drawer';
import { QuantityChangeDialog } from '../quantity-change-dialog/quantity-change-dialog';
import styles from './quantity.module.scss';

export const Quantity = () => {
  const [isProjectSummaryOpen, setIsProjectSummaryOpen] = useState(false);
  const [isQuantityChangeDialogOpen, setIsQuantityChangeDialogOpen] = useState(false);
  const [isQuantityIncreasing, setIsQuantityIncreasing] = useState(false);
  const newQuantity = useRef('');
  const { t } = useTranslation();
  const { trackChangeQuantityProjectSummary, trackViewProjectSummary } = useAnalyticsContext();
  const queryParams = useQueryParams();
  const { pathname } = useLocation();
  const isEditor = pathname.includes('/edit/');
  const [isSavingQuantity, setIsSavingQuantity] = useState(false);
  const navigate = useNavigate();
  const { getEnvelopeSelection } = useEnvelopeSelection();
  const { appState, appDispatch } = useAppContext();
  const { handleSetCount, maxCount } = useAddressSelection();
  const { productQuantity } = appState;
  const hasQuantityUpdatedAfterPageLoad = useRef(false);
  const isQuantityLinkEnabled = config?.header?.quantityLink;
  const isQuantityInputEnabled = config?.header?.quantityInput;
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const projectId = initializedData?.project_id;
  const isBlankEnvelope = getEnvelopeSelection() === EnvelopeType.Blank;
  const {
    register,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      quantity: productQuantity,
    },
    mode: 'onChange',
    delayError: 250,
  });
  const { IS_MULT_ADDRESS_ENABLED } = useFeatureFlags();

  const isValidQuantity = (quantity: string) => Number(quantity) > 0;
  const onCloseSummary = () => {
    setIsProjectSummaryOpen(false);
  };
  const updateQuantity = useCallback(
    async (quantity: string) => {
      setIsQuantityChangeDialogOpen(false);
      setIsSavingQuantity(true);
      try {
        setProductQuantity(appDispatch, quantity);
        queryParams.set('qty', quantity);
        navigate({ pathname, search: queryParams.toString() }, { replace: true });
        await updateProductQuantity(quantity, projectId);
      } catch (e) {
        setProductQuantity(appDispatch, productQuantity || '1');
        queryParams.set('qty', productQuantity || '1');
        navigate({ pathname, search: queryParams.toString() }, { replace: true });
        setValue('quantity', productQuantity || '1');
        setIsToasterOpen(appDispatch, {
          title: t('summaryDrawer.quantityChangeErrorTitle'),
          children: t('summaryDrawer.quantityChangeError'),
          variant: ToastVariants.Error,
        });
      }
      setIsSavingQuantity(false);
    },
    [queryParams, window.history],
  );

  const handleQuantityChange = (quantity: string, addressCount?: number) => {
    if (isQuantityIncreasing) {
      handleSetCount(addressCount ? addressCount : Number(quantity));
      updateQuantity(quantity);
    } else {
      const newQuantity = Number(quantity);
      if (newQuantity < maxCount) {
        handleSetCount(newQuantity);
      }
      updateQuantity(quantity);
    }
    setIsQuantityChangeDialogOpen(false);
  };

  const handleDialogActionButtonClick = () => {
    handleQuantityChange(newQuantity.current, maxCount);
  };

  const handleDialogCancelButtonClick = () => {
    if (isQuantityIncreasing) {
      handleQuantityChange(newQuantity.current);
    } else {
      setIsQuantityChangeDialogOpen(false);
      setValue('quantity', productQuantity);
    }
  };

  const handleClose = () => {
    setValue('quantity', productQuantity);
    setIsQuantityChangeDialogOpen(false);
  };

  const onUpdateQuantity = (quantity: string) => {
    if (productQuantity === quantity) {
      return;
    }
    if (isBlankEnvelope || isEditor) {
      updateQuantity(quantity);
      return;
    }
    setIsQuantityIncreasing(Number(quantity) > Number(productQuantity));
    if (IS_MULT_ADDRESS_ENABLED) {
      setIsQuantityChangeDialogOpen(true);
    }
    newQuantity.current = quantity;
  };

  // Setting max quantity to the address selection context
  const { handleSetMaxCount } = useAddressSelection();

  // Debounce the update quantity function to avoid multiple API calls in a short time
  // when the user is typing in the input quantity field
  const debouncedUpdate = useDebouncedCallback((value) => {
    onUpdateQuantity(value);
  }, 1000);

  useEffect(() => {
    handleSetMaxCount(Number(appState.productQuantity));
  }, [appState.productQuantity]);

  useEffect(() => {
    // start listening for changes in product quantity
    // after we've loaded the page and confirmed
    // the state equals the query params
    if (productQuantity === queryParams.get('qty') && hasQuantityUpdatedAfterPageLoad.current) {
      trackChangeQuantityProjectSummary();
    } else if (productQuantity === queryParams.get('qty')) {
      hasQuantityUpdatedAfterPageLoad.current = true;
    }
  }, [productQuantity]);

  useEffect(() => {
    setProductQuantity(appDispatch, queryParams.get('qty') ?? '1');
  }, []);

  useEffect(() => {
    if (isProjectSummaryOpen) {
      trackViewProjectSummary();
    }
  }, [isProjectSummaryOpen]);

  return (
    <>
      <QuantityChangeDialog
        isIncrementing={isQuantityIncreasing}
        actionButton={handleDialogActionButtonClick}
        cancelButton={handleDialogCancelButtonClick}
        isOpen={isQuantityChangeDialogOpen}
        onClose={handleClose}
      />
      {isQuantityLinkEnabled && (
        <>
          <Button
            testId="quantity-link"
            click={() => setIsProjectSummaryOpen(!isProjectSummaryOpen)}
            type={ButtonTypes.Button}
            mode={ButtonModes.TextLink}
            addClass={styles['quantity-link']}
            endIcon={{ name: isProjectSummaryOpen ? IconNames.ArrowsCaretUpBold : IconNames.ArrowsCaretDownBold }}
          >
            {t('header.quantity', { productQuantity })}
          </Button>
          {isProjectSummaryOpen && (
            <SummaryDrawer
              isOpen={isProjectSummaryOpen}
              onClose={onCloseSummary}
              quantity={productQuantity}
              onUpdateQuantity={onUpdateQuantity}
              isSavingQuantity={isSavingQuantity}
              register={register}
            />
          )}
        </>
      )}
      {isQuantityInputEnabled && (
        <TextField
          addClass={styles['quantity-input']}
          label="Quantity"
          domId="quantity"
          testId="quantity-input"
          isError={Boolean(errors.quantity)}
          helperText={errors.quantity ? errors.quantity.message : ''}
          type="number"
          onChange={(event) => isValidQuantity(event.target.value) && debouncedUpdate(event.target.value)}
          onKeyDown={(event) => ['e', 'E', '+', '-', '.'].includes(event.key) && event.preventDefault()}
          register={register('quantity', {
            required: { value: true, message: t('header.quantityRequiredError') },
            valueAsNumber: true,
          })}
        />
      )}
    </>
  );
};
