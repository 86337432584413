import React from 'react';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { Icon, IconNames } from '@hallmark/web.core.display.icon';
import { LoadingIndicator } from '@hallmark/web.core.feedback.loading-indicator';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import { ImageResponse } from '../../../../global-types/images';
import { PhotosUploadedProps } from '../photo-drawer-types';
import styles from '../photo-drawer.module.scss';
import { PhotoImage } from './photo-image';

export const PhotosUploaded = ({
  onUploadImages,
  onDeleteImages,
  photoTrayImages,
  labels,
  selectedPhotos,
  setSelectedPhotos,
  isDeleting,
  setIsDeleting,
  onCancelDelete,
  onUploadError,
  onPhotoSelect,
  inputFileRef,
  isUploading,
  footerContent,
  isFooterSticky = false,
}: PhotosUploadedProps): React.ReactElement => {
  const onSelectPhoto = (photo: ImageResponse, e: React.MouseEvent) => {
    e.stopPropagation();
    if (isDeleting) {
      const isPhotoSelected = selectedPhotos.some((p) => p.photo_tray_image_id === photo.photo_tray_image_id);
      if (isPhotoSelected) {
        // If photo is already selected, deselect it (remove it from array)
        setSelectedPhotos(selectedPhotos.filter((p) => p.photo_tray_image_id !== photo.photo_tray_image_id));
      } else {
        setSelectedPhotos([...selectedPhotos, photo]);
      }
    }

    if (onPhotoSelect && !isDeleting) {
      onPhotoSelect(photo);
    }
  };

  const handleUploadImages = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    try {
      onUploadImages(e as unknown as React.ChangeEvent<HTMLInputElement>);
    } catch (error) {
      onUploadError(error as Error);
    }
  };

  const handleDeleteButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isDeleting) {
      onDeleteImages(selectedPhotos, e);
      // After deleting images, reset isDeleting and selectedPhotos
      setSelectedPhotos([]);
      setIsDeleting(false);
    } else {
      setIsDeleting(true);
    }
  };

  return (
    <div className={styles['photos-uploaded-container']}>
      <div className={styles['photos-uploaded-list']}>
        {photoTrayImages && (
          <Button
            testId="photo-upload-button"
            click={(e) => {
              inputFileRef.current?.click();
              handleUploadImages(e as React.MouseEvent);
            }}
            addClass={styles['photo-upload-button']}
          >
            <Icon addClass={styles['photo-lot-icon']} name={IconNames.PhotoslotBold} size={40} />
            <Typography
              addClass={styles['no-photos-uploaded-title']}
              color={BrandColors.MediumGray}
              variant={TypographyVariants.Body}
            >
              {labels.uploadPhoto}
            </Typography>
          </Button>
        )}
        {photoTrayImages.map((photo) => (
          <PhotoImage
            isDeleting={isDeleting}
            key={photo.photo_tray_image_id}
            photo={photo}
            isSelected={selectedPhotos.some((p) => p.photo_tray_image_id === photo.photo_tray_image_id)}
            onSelect={(photo, e) => onSelectPhoto(photo, e)}
          />
        ))}
        {isUploading && <LoadingIndicator isCenteredInParent={true} parentHeight="120px" />}
        {footerContent && <div>{footerContent}</div>}
      </div>
      <div className={`${styles['photo-upload-buttons-container']} ${isFooterSticky ? styles.sticky : ''}`}>
        <Button
          addClass={styles['photo-delete-button']}
          testId="photo-delete-button"
          mode={isDeleting ? ButtonModes.Primary : ButtonModes.Secondary}
          click={(e) => handleDeleteButtonClick(e as React.MouseEvent)}
          endIcon={{ name: IconNames.TrashBold, size: 14 }}
          disabled={isDeleting && selectedPhotos.length === 0}
        >
          {labels.deleteImage}
        </Button>
        {isDeleting && (
          <Button
            testId="cancel-delete-button"
            mode={ButtonModes.Secondary}
            click={(e) => onCancelDelete(e as React.MouseEvent)}
          >
            {labels.cancel}
          </Button>
        )}
      </div>
    </div>
  );
};
