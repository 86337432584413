import { ReactNode } from 'react';
import { BulkUploadAddressData } from '../../components/bulk-upload-validation-modal/bulk-upload-validation-modal-types';

export type AddressBookProviderProps = {
  children: ReactNode;
};

export type AddressBookContextState = {
  isBulkUploadDialogOpen: boolean;
  isValidationModalOpen: boolean;
  addressesWithErrors: BulkUploadAddressData[];
};

export enum AddressBookType {
  SET_IS_BULK_UPLOAD_DIALOG_OPEN = 'SET_IS_BULK_UPLOAD_DIALOG_OPEN',
  SET_IS_VALIDATION_MODAL_OPEN = 'SET_IS_VALIDATION_MODAL_OPEN',
  SET_ADDRESSES_WITH_ERRORS = 'SET_ADDRESSES_WITH_ERRORS',
}

export type SetIsBulkUploadDialogOpen = {
  type: AddressBookType.SET_IS_BULK_UPLOAD_DIALOG_OPEN;
  payload: boolean;
};

export type SetIsValidationModalOpen = {
  type: AddressBookType.SET_IS_VALIDATION_MODAL_OPEN;
  payload: boolean;
};

export type SetAddressesWithErrors = {
  type: AddressBookType.SET_ADDRESSES_WITH_ERRORS;
  payload: BulkUploadAddressData[];
};

export type AddressBookAction = SetIsBulkUploadDialogOpen | SetIsValidationModalOpen | SetAddressesWithErrors;

export type Dispatch = (action: AddressBookAction) => void;

export type CreateContextProps =
  | {
      addressBookState: AddressBookContextState;
      addressBookDispatch: Dispatch;
    }
  | undefined;
