import React, { useRef, useState } from 'react';
import { CarouselImageDotButton } from '@hallmark/web.core.surfaces.carousel';
import { useAnalyticsContext } from '../../context/analytics-context';
import { setActiveCardIndex, useCardContext } from '../../context/card-context';
import { CardFaceData, CardFacePreviewImage } from '../../global-types';
import { config } from '../../regional-config';
import { ButtonGroup } from '../button-group/button-group';
import { CardSelectBarProps } from './card-select-bar-types';
import styles from './card-select-bar.module.scss';
import { getThumbnailImage } from './utils';

export const CardSelectBar = ({ faces, slideIndex, handleIndexChange, mode }: CardSelectBarProps) => {
  const { cardDispatch } = useCardContext();
  const [focusedTabIndex, setFocusedTabIndex] = useState(0);
  const tabsRefs = useRef<HTMLButtonElement[]>([]);
  const { pageNavigationButtonClicked } = useAnalyticsContext();

  let filteredFaces: CardFaceData[] | CardFacePreviewImage[];
  if (mode === 'edit') {
    const faceList = faces as CardFaceData[];
    filteredFaces = faceList.filter((face) => face.editorDisplayIndicator);
  } else {
    filteredFaces = faces as CardFacePreviewImage[];
  }

  function onKeyDown(e: React.KeyboardEvent) {
    const lastTab = tabsRefs.current?.length - 1;
    let currentFocusedTabIndex = focusedTabIndex;
    if (e.key === 'ArrowLeft') {
      if (currentFocusedTabIndex === 0) {
        currentFocusedTabIndex = lastTab;
      } else {
        currentFocusedTabIndex -= 1;
      }
    } else if (e.key === 'ArrowRight') {
      if (currentFocusedTabIndex === lastTab) {
        currentFocusedTabIndex = 0;
      } else {
        currentFocusedTabIndex += 1;
      }
    }
    const currentTab = tabsRefs.current[`${currentFocusedTabIndex}`];
    if (tabsRefs) {
      currentTab.focus();
      currentTab.tabIndex = 0;
      currentTab.ariaSelected = 'false';
    }
    setFocusedTabIndex(currentFocusedTabIndex);
  }

  return (
    <ButtonGroup
      defaultClickedIndex={slideIndex}
      activeClassOverride={styles['active-image-dot-button']}
      addClass={styles['carousel-image-dot-container']}
      onIndexChange={handleIndexChange}
      groupRole="tablist"
      ariaLabel="Carousel Slides"
    >
      {filteredFaces.map((face, index) => (
        <CarouselImageDotButton
          click={() => {
            pageNavigationButtonClicked.current = 'Thumbnail';
            setActiveCardIndex(cardDispatch, face.faceNumber - 1);
          }}
          key={`face-${index}-button`}
          image={getThumbnailImage(face)}
          altText={face?.type?.replace('-', ' ')}
          addClass={styles['image-dot-button']}
          ariaLabel={face.type}
          onKeyDown={onKeyDown}
          role="tab"
          ariaSelected={focusedTabIndex === index}
          ariaControls={`${index + 1}_TABPANEL`}
          id={`${index + 1}_TAB`}
          ref={(ref: HTMLButtonElement) => {
            tabsRefs.current[`${index}`] = ref;
          }}
          tabIndex={focusedTabIndex === index ? 0 : -1}
          testId="carousel-dot-button"
        >
          {config.cardEditor?.faceTypeText ? face?.type?.replace('-', ' ') : null}
        </CarouselImageDotButton>
      ))}
    </ButtonGroup>
  );
};
