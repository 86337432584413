import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { CustomFabricObject } from '../../../global-types/canvas';
import { useActiveCanvas } from '../../../hooks';
import { Drawer } from '../drawer/drawer';
import { DrawerPositions } from '../drawer/drawer-types';
import { HeaderContent, FooterContent } from './fragments';
import { RotateDrawerProps } from './rotate-drawer-types';
import styles from './rotate-drawer.module.scss';

export const RotateDrawer = ({
  isOpen,
  onDrawerCancel,
  onDrawerApply,
  onChangeCallback,
  initialAngle,
}: RotateDrawerProps): React.ReactElement => {
  const { t } = useTranslation();
  const canvas = useActiveCanvas();
  const activeObject = canvas?.current?.getActiveObject();
  const currentAngle = activeObject?.angle ?? 0;

  const handleCancel = () => {
    const activeObject = canvas?.current?.getActiveObject() as CustomFabricObject;
    if (activeObject) {
      activeObject._setOriginToCenter();
      activeObject.set('angle', initialAngle);
      activeObject._resetOrigin();
      canvas?.current?.renderAll();
    }
    onDrawerCancel();
  };
  return (
    <Drawer
      drawerPosition={DrawerPositions.Bottom}
      hideCloseButton={true}
      onClose={onDrawerCancel}
      isOpen={isOpen}
      addClass={styles['rotate-drawer']}
      headerContent={<HeaderContent onChangeCallback={onChangeCallback} initialValue={currentAngle} />}
      role="toolbar"
      showVeil={false}
    >
      <FooterContent onDrawerCancel={handleCancel} onDrawerApply={onDrawerApply} />
      <Typography variant={TypographyVariants.SRT}>{t('rotateDrawer.rotateDrawer')}</Typography>
    </Drawer>
  );
};

RotateDrawer.defaultProps = {
  addClass: '',
};
