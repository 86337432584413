import React from 'react';
import classNames from 'classnames';
import { Image } from '@hallmark/web.core.display.image';
import { PhotoImageProps } from '../photo-drawer-types';
import styles from '../photo-drawer.module.scss';

export const PhotoImage = ({ photo, isSelected, onSelect, isDeleting }: PhotoImageProps): React.ReactElement => {
  const SelectedPhotoIcon = () => (
    <div className={styles['outer-circle']}>
      <div className={styles['inner-circle']}></div>
    </div>
  );

  const classes = classNames(styles['photo-container'], styles['photo-selectable']);
  const [isLoaded, setIsLoaded] = React.useState(false);

  return (
    <div
      className={isDeleting ? classes : styles['photo-container']}
      onClick={onSelect.bind(null, photo)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          onSelect.bind(null, photo);
        }
      }}
      role="button"
      tabIndex={0}
      data-testid={`uploaded-photo-${photo.image_id}`}
    >
      {isSelected && <SelectedPhotoIcon />}
      <Image
        addClass={!isLoaded || (!isSelected && isDeleting) ? styles['photo-loading'] : undefined}
        onLoad={() => setIsLoaded(true)}
        src={photo.image_url}
        alt={'Uploaded by user'}
        lazyLoad={false}
        onDragStart={(e) => {
          e.dataTransfer.setData('id', photo.photo_tray_image_id);
        }}
        id={photo.photo_tray_image_id}
        draggable={true}
      />
    </div>
  );
};
