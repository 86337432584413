// Sets current Dimensions
import { RefObject } from 'react';
import { fabric } from 'fabric';

// Sets current Dimensions
export const setCurrentDimensions = (canvasParentRef: RefObject<HTMLElement>, canvasRef: RefObject<fabric.Canvas>) => {
  canvasRef.current?.setHeight(canvasParentRef.current?.clientHeight || '100%');
  canvasRef.current?.setWidth(canvasParentRef.current?.clientWidth || '100%');
  canvasRef.current?.renderAll();
};
