import { useLocation } from 'react-router-dom';
import { InitializationData } from '../../../global-types';
import { useActiveCanvas } from '../../../hooks/use-active-canvas';
import { addMultipleFontFaces, loadOpenTypeFonts, initializeCanvasExtensions } from '../../../utils';

export const useLoadFontFaces = () => {
  const { pathname, search } = useLocation();
  const activeCanvas = useActiveCanvas();

  const loadFontFaces = async (formatedSkuData: InitializationData) => {
    const { fonts } = formatedSkuData.font_collection;
    formatedSkuData.entryURL = pathname + search;
    await addMultipleFontFaces(fonts);
    const openTypeFonts = await loadOpenTypeFonts(fonts);

    initializeCanvasExtensions({
      fonts: openTypeFonts,
      onTextTransformedToPath: (path) => activeCanvas?.current?.add(path),
    });
  };

  return loadFontFaces;
};
