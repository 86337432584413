import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { setLineItemUUID, useInitializationDataContext } from '../context/data-context';
import { useQueryParams } from './use-query-params';

export const useLineItemUUID = () => {
  const {
    initializationDataDispatch,
    initializedDataState: { lineItemUUID },
  } = useInitializationDataContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  useEffect(() => {
    const UUIDFromQuery = queryParams.get('lineItemUUID');
    if (UUIDFromQuery && lineItemUUID) {
      return;
    }
    if (UUIDFromQuery) {
      setLineItemUUID(initializationDataDispatch, UUIDFromQuery);
      return;
    }
    if (lineItemUUID) {
      queryParams.append('lineItemUUID', lineItemUUID);
      navigate({ pathname, search: `?${queryParams.toString()}` }, { replace: true });
    }
  }, [queryParams, lineItemUUID]);
};
