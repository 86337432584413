import { DeliveryModes } from '../../../components/address-form/address-form-types';
import { InitBody } from '../../../global-types/initialization-data';

type InitializationRequestParams = {
  projectName: string;
  productId: string;
  productTypeCode: string;
  isLoggedIn: boolean;
  productQuantity: number | string; // Assuming it can be a string since it's converted to a number
  isOneToMany: boolean;
};

export const getInitializationRequestBody = ({
  projectName,
  productId,
  productTypeCode,
  isLoggedIn,
  productQuantity,
  isOneToMany,
}: InitializationRequestParams): InitBody => {
  const isDigitalFulfillment = productTypeCode === 'D';

  const initializeRequestBody: InitBody = {
    product_id: productId,
    is_draft: isLoggedIn,
    purchase_quantity: Number(productQuantity),
    // For digital greetings, we should treat them as a POD projects from an API perspective
    name: isDigitalFulfillment ? 'POD Project' : projectName,
    project_type_code: isDigitalFulfillment ? 'P' : productTypeCode,
    is_digital_fulfillment: isDigitalFulfillment,
  };

  // If the product is a digital fulfillment type, we do not set delivery mode or envelope type
  if (!isDigitalFulfillment) {
    // If the product is 1:Many, set delivery mode to 'S'; otherwise, set it to 'MailToRecipient'.
    initializeRequestBody.delivery_mode = isOneToMany ? DeliveryModes.ShipToMe : DeliveryModes.MailToRecipient;
    // On initialize, envelope_type for 1:M starts as undefined so user can set it later
    initializeRequestBody.envelope_type = isOneToMany ? undefined : 'C';
  }

  return initializeRequestBody;
};
