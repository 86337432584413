import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useInitializationDataContext } from '../../../context/data-context';
import { EnvelopeType } from '../../../global-types/envelope';
import { useSkipAddressValidation } from '../../../hooks/use-skip-address-validation';
import { updateProject } from '../../../services/customization';
import { getEnvelopeCode } from '../../../utils/address/get-envelope-code';
import { useEditorNavigation } from '../../editor/hooks/use-editor-navigation';
import { EnvelopeSelectionForm } from '../select-envelope-types';

export const useEnvelopeSelection = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const [searchParams] = useSearchParams(search);
  const { handleIndexChange } = useEditorNavigation();
  const projectId = initializedData?.project_id;

  const skipAddressValidation = useSkipAddressValidation();
  const getEnvelopeSelection = () => searchParams.get('envelope');

  const envelopeForm = useForm<EnvelopeSelectionForm>({
    mode: 'onChange',
    defaultValues: { envelopeOption: (getEnvelopeSelection() as EnvelopeType) || undefined },
  });

  const selectedEnvelope = envelopeForm.watch('envelopeOption') || getEnvelopeSelection();

  const handleBack = () => {
    const lastProjectLoaded = sessionStorage.getItem('lastProjectLoaded');

    if (!lastProjectLoaded && !projectId) return;

    searchParams.delete('envelope');
    navigate({
      pathname: lastProjectLoaded
        ? `/card/customization/edit/${lastProjectLoaded}`
        : `/card/customization/edit/${projectId}`,
      search: searchParams.toString(),
    });
    handleIndexChange(0, 0);
  };

  const handleContinue = () => {
    if (!selectedEnvelope) return;
    if (selectedEnvelope === EnvelopeType.Blank) {
      skipAddressValidation();
      return;
    }

    searchParams.set('envelope', selectedEnvelope);
    // Call /update API to update project's envelope type, then navigate
    const data = {
      envelope_type: getEnvelopeCode(selectedEnvelope),
    };
    return new Promise<void>((resolve, reject) => {
      updateProject(data, projectId)
        .then(() => {
          navigate({ pathname: '/card/customization/address', search: searchParams.toString() });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  return {
    envelopeForm,
    selectedEnvelope,
    getEnvelopeSelection,
    handleBack,
    handleContinue,
  };
};
