import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { fabric } from 'fabric';
import { IconButtonBrandColors } from '@hallmark/web.core.buttons.icon-button';
import { LoadingIndicator } from '@hallmark/web.core.feedback.loading-indicator';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { thumbnailWidth } from '../../../constants';
import { addPhotoTrayImages, setAllDrawersClosed, setIsToasterOpen, useAppContext } from '../../../context/app-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { ImageResponse } from '../../../global-types';
import { PhotoTrayCustomFormats } from '../../../global-types/images';
import { useDeletePhotoTrayImages, useFeatureFlags, usePhotoDrawerInfiniteScroll } from '../../../hooks';
import { linkPhoto } from '../../../services';
import { CanvasDataTypes } from '../../../utils';
import { DeleteImagesConfirmationDialog } from '../../delete-images-dialog/delete-images-confirmation-dialog';
import { useImageUploadHandlers } from '../drawer-container/hooks/use-image-upload-handlers';
import { Drawer } from '../drawer/drawer';
import { DrawerPositions } from '../drawer/drawer-types';
import { NoPhotosUploaded } from './fragments/no-photos-uploaded';
import { PhotosUploaded } from './fragments/photos-uploaded';
import styles from './photo-drawer.module.scss';

export const PhotoDrawer = () => {
  const { appState, appDispatch } = useAppContext();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const closeAllDrawers = useCallback(() => setAllDrawersClosed(appDispatch), [appDispatch]);
  const { isImageUploadToPhotoZoneOpen, isImageUploadDrawerOpen, photoTrayImagesPage } = appState;
  const [selectedPhotos, setSelectedPhotos] = useState<ImageResponse[]>([]);
  const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false);
  const customFormats = PhotoTrayCustomFormats;
  let isAddingToCanvas = false;

  const [hasMoreImages, setHasMoreImages] = useState(true);
  const { IS_DRAWER_PAGINATION_ENABLED } = useFeatureFlags();
  const observerTarget = useRef(null);
  const footerObserverTarget = <div ref={observerTarget} style={{ height: '1px', marginTop: '150px' }}></div>;
  const { onImageUpload } = useImageUploadHandlers();
  const {
    initializedDataState: { data: initializedData, isUK },
  } = useInitializationDataContext();

  const {
    isUploading,
    setIsUploading,
    onUploadImages,
    photoTrayImagesFinal,
    setPhotoTrayImages,
    handleDeleteImageLocally,
  } = usePhotoDrawerInfiniteScroll(
    appDispatch,
    isImageUploadDrawerOpen,
    isImageUploadToPhotoZoneOpen,
    setHasMoreImages,
    hasMoreImages,
    IS_DRAWER_PAGINATION_ENABLED,
    photoTrayImagesPage,
    observerTarget,
  );

  useEffect(() => {
    if (isImageUploadDrawerOpen || (isImageUploadToPhotoZoneOpen && IS_DRAWER_PAGINATION_ENABLED)) {
      setHasMoreImages(true);
      addPhotoTrayImages(appDispatch, []);
    }
  }, [isImageUploadDrawerOpen, isImageUploadToPhotoZoneOpen, appDispatch, setHasMoreImages]);

  useEffect(() => {
    setHasMoreImages(false);
  }, [closeAllDrawers]);

  const {
    setImagesToDelete,
    deleteImagesConfirmationOpen,
    handleDeleteImagesConfirmation,
    handleOpenDeleteImagesConfirmationDialog,
    isDeleting,
  } = useDeletePhotoTrayImages(setPhotoTrayImages, handleDeleteImageLocally, setIsDeleteMode);

  const onDeleteImages = (photosToDelete: ImageResponse[]) => {
    handleOpenDeleteImagesConfirmationDialog(true);
    setImagesToDelete(photosToDelete);
  };
  const ConfirmDeleteDialog = {
    dialogTitle: t('photoDrawer.deleteDialog.dialogTitle'),
    dialogContent: t('photoDrawer.deleteDialog.dialogContent'),
    confirmButtonText: t('photoDrawer.deleteDialog.actionButton'),
    cancelButtonText: t('photoDrawer.deleteDialog.cancelButton'),
  };
  const NoPhotosUploadedLabels = {
    title: t('photoDrawer.noPhotosUploaded.title'),
    steps: t('photoDrawer.noPhotosUploaded.steps', { returnObjects: true }) as string[],
  };
  const PhotosUploadedLabels = {
    uploadPhoto: t('photoDrawer.photosUploaded.uploadPhoto'),
    deleteImage: t('photoDrawer.photosUploaded.deleteImage'),
    cancel: t('photoDrawer.photosUploaded.cancel'),
  };

  const onUploadImageClick = useCallback(async () => {
    try {
      setIsUploading(true);
      if (inputFileRef.current) {
        const event = { currentTarget: inputFileRef.current } as React.ChangeEvent<HTMLInputElement>;
        await onUploadImages(event);
        const uniqueImages = Array.from(new Set([...photoTrayImagesFinal]));
        setPhotoTrayImages(uniqueImages);
        if (photoTrayImagesFinal.length > 0) {
          setIsUploading(false);
        }
      }
    } catch (error) {
      setIsUploading(false);
    }
  }, [onUploadImages, photoTrayImagesFinal, setIsUploading]);

  const onUploadError = (error: Error) => {
    setIsToasterOpen(appDispatch, {
      title: 'Error uploading image',
      children: error.message,
      variant: ToastVariants.Error,
    });
  };

  const onPhotoSelect = async (photo: ImageResponse) => {
    try {
      if (isAddingToCanvas) {
        return;
      }
      isAddingToCanvas = true;

      const data = {
        image_reference_id: photo.image_reference_id || photo.photo_tray_image_id,
      };

      const response = await linkPhoto(data, initializedData?.project_id as string);

      const imageElement = new window.Image();
      imageElement.crossOrigin = 'anonymous';

      const urlObj = new URL(response.data?.image_url || '');
      urlObj.searchParams.delete('w');
      imageElement.src = urlObj.toString();

      imageElement.onload = () => {
        const imgInstance = new fabric.Image(imageElement, {
          top: 100,
          left: 100,
          name: response.data?.image_id,
          data: {
            ...data,
            type: CanvasDataTypes.UserImage,
          },
          borderColor: isUK ? '#333333' : '#604099',
        });

        if (onImageUpload) {
          onImageUpload(imgInstance);
          closeAllDrawers();
        }
      };
    } catch (error) {
      onUploadError(error as Error);
    }
  };

  const classes = classNames(styles['photo-drawer']);

  const onCancelDelete = (e?: React.MouseEvent) => {
    if (e) e.stopPropagation();
    setIsDeleteMode(false);
    setSelectedPhotos([]);
  };

  const handleDeleteImages = (photos: ImageResponse[], e: React.MouseEvent) => {
    e.stopPropagation();
    onDeleteImages(photos);
  };

  const handleClosingDrawer = () => {
    onCancelDelete();
    closeAllDrawers();
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (inputFileRef.current && event.dataTransfer.files.length) {
      inputFileRef.current.files = event.dataTransfer.files;
      const changeEvent = new Event('change', { bubbles: true });
      inputFileRef.current.dispatchEvent(changeEvent);
    }
  };

  const updatedPhotoTrayImages = photoTrayImagesFinal.map((image) => ({
    ...image,
    thumbnail_url: `${image.thumbnail_url}?${thumbnailWidth}`,
  }));
  const isLoading = (isUploading && photoTrayImagesFinal.length < 1) || isDeleting;
  const isOpen = isImageUploadDrawerOpen || isImageUploadToPhotoZoneOpen;
  const showPhotosUploaded = updatedPhotoTrayImages !== undefined && updatedPhotoTrayImages.length > 0;

  return (
    <>
      <Drawer
        drawerPosition={DrawerPositions.Left}
        closeButtonText="Close Drawer"
        isOpen={isOpen}
        onClose={handleClosingDrawer}
        addClass={classes}
        drawerTestId="photo-drawer"
        ariaLabel="Photo Drawer"
        closeIconClass={styles['drawer-close-icon']}
        headerContent={
          <div className={styles['drawer-header']}>
            <Typography variant={TypographyVariants.Headline5} color={IconButtonBrandColors.DarkGray}>
              {t('photoDrawer.title')}
            </Typography>
          </div>
        }
        showVeil={false}
      >
        {!isLoading && (
          <div className={styles['drawer-content']} onDrop={handleDrop} onDragOver={handleDragOver}>
            <input
              type="file"
              ref={inputFileRef}
              onChange={onUploadImageClick}
              style={{ display: 'none' }}
              accept={customFormats}
              multiple
              data-testid={'photo-drawer-input'}
            />
            {showPhotosUploaded ? (
              <PhotosUploaded
                labels={PhotosUploadedLabels}
                photoTrayImages={updatedPhotoTrayImages}
                onDeleteImages={(selectedPhotos, e) =>
                  handleDeleteImages(selectedPhotos as ImageResponse[], e as React.MouseEvent)
                }
                onUploadImages={onUploadImageClick}
                selectedPhotos={selectedPhotos}
                setSelectedPhotos={setSelectedPhotos}
                isDeleting={isDeleteMode}
                setIsDeleting={setIsDeleteMode}
                onCancelDelete={onCancelDelete}
                onUploadError={onUploadError}
                onPhotoSelect={onPhotoSelect}
                inputFileRef={inputFileRef}
                isUploading={isUploading}
                footerContent={footerObserverTarget}
                isFooterSticky={true}
              />
            ) : (
              <NoPhotosUploaded labels={NoPhotosUploadedLabels} inputFileRef={inputFileRef} />
            )}
          </div>
        )}
        {isLoading && <LoadingIndicator />}
      </Drawer>
      <div ref={observerTarget}></div>
      <DeleteImagesConfirmationDialog
        isOpen={deleteImagesConfirmationOpen}
        onClose={() => handleOpenDeleteImagesConfirmationDialog(false)}
        onConfirm={handleDeleteImagesConfirmation}
        domId="delete-confirmation-dialog"
        contentTitle={ConfirmDeleteDialog.dialogTitle}
        contentText={ConfirmDeleteDialog.dialogContent}
        actionButtonText={ConfirmDeleteDialog.confirmButtonText}
        cancelButtonText={ConfirmDeleteDialog.cancelButtonText}
      />
    </>
  );
};
