import { useCallback, useEffect } from 'react';
import { fabric } from 'fabric';
import { FabricHookProps, FabricHookReturn, FabricObjects } from '../components/fabric-canvas/fabric-canvas-types';
import { setCurrentDimensions } from '../utils/canvas-utils/set-dimensions';

export const useFabric = ({
  canvasRef,
  canvasSettings,
  canvasElements,
  canvasParentRef,
  onReady,
  onObjectAdded,
  onObjectRemoved,
  onObjectModified,
}: FabricHookProps): FabricHookReturn | (() => void) => {
  const fabricRef = useCallback((element: string) => {
    if (!element) {
      // Needed to prevent crashing between multiple fabric views
      return canvasRef.current?.dispose();
    }
    canvasRef.current = new fabric.Canvas(element, canvasSettings);

    if (canvasElements && canvasElements.length > 0) {
      canvasElements.forEach((element: FabricObjects) => {
        if (canvasRef.current) {
          canvasRef.current.add(element);
        }
      });
    }

    const resizeCanvas = () => {
      if (canvasRef && canvasParentRef) {
        setCurrentDimensions(canvasParentRef, canvasRef);
      }
    };

    window.addEventListener('resize', resizeCanvas, false);

    if (onReady) {
      onReady(canvasRef.current);
    }

    if (onObjectAdded) {
      canvasRef.current.on('object:added', onObjectAdded);
    }

    if (onObjectRemoved) {
      canvasRef.current.on('object:removed', onObjectRemoved);
    }

    if (onObjectModified) {
      canvasRef.current.on('object:modified', onObjectModified);
    }

    return () => {
      if (canvasRef.current) {
        canvasRef.current.dispose();
      }
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  useEffect(() => {
    if (canvasParentRef) {
      setCurrentDimensions(canvasParentRef, canvasRef);
    }
  }, []);

  return fabricRef;
};
