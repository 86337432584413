import { useCallback } from 'react';
import { AddressForm as AddressFormData, FormValidation } from '../../../global-types';
import { useSkipAddressValidation } from '../../../hooks/use-skip-address-validation';
import { mapAddressesByType } from '../../../utils/address/map-address-by-type';
import { useAddressConfirmationDialog } from './use-address-confirmation-dialog';

export const useAddressSkipValidation = ({ submittedAddresses, setSubmittedAddresses, setShouldSave, envelope }) => {
  const { closeAddressConfirmationDialog } = useAddressConfirmationDialog();
  const skipAddressValidation: () => void = useSkipAddressValidation({ submittedAddresses, envelope });

  // Function to be triggered when all the addresses where confirmed in address confirmation dialog
  const onSkipValidation = useCallback(
    (addressesToValidate: FormValidation[]) => {
      const skippedValidationAddresses = addressesToValidate
        .map((address) => {
          const { addressType } = address;
          const skippedAddress = submittedAddresses[`${addressType}`];
          return skippedAddress ? { ...skippedAddress, skip_usps_validation: true } : null;
        })
        .filter((address) => address !== null) as AddressFormData[];

      setSubmittedAddresses((addresses) => ({
        ...addresses,
        ...mapAddressesByType<AddressFormData>(skippedValidationAddresses),
      }));
      closeAddressConfirmationDialog();
      setShouldSave(true);
    },
    [closeAddressConfirmationDialog, submittedAddresses],
  );

  /**
   * Loops through saved addresses and called delete address endpoint for each address,  There's no
   * use case for this now, but will be if we decide to select a blank envelope on 1:1 cards
   */

  return {
    onSkipValidation,
    skipAddressValidation,
  };
};
