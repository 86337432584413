import React from 'react';
import { Icon, IconNames } from '@hallmark/web.core.display.icon';
import { List, ListItem, ListType } from '@hallmark/web.core.typography.list';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import { NoPhotosUploadedProps } from '../photo-drawer-types';
import styles from '../photo-drawer.module.scss';

export const NoPhotosUploaded = ({
  labels,
  onDrawerImageUpload,
  inputFileRef,
}: NoPhotosUploadedProps): React.ReactElement => {
  const handleUploadDrawerImages = () => {
    onDrawerImageUpload && onDrawerImageUpload();
  };

  return (
    <button
      data-testid={`photo-upload-button`}
      className={styles['no-photos-uploaded-container']}
      onClick={() => {
        inputFileRef.current?.click();
        handleUploadDrawerImages();
      }}
    >
      <Icon addClass={styles['photo-lot-icon']} name={IconNames.PhotoslotBold} size={40} />
      <Typography addClass={styles['no-photos-uploaded-title']} variant={TypographyVariants.BodyBold}>
        {labels.title}
      </Typography>
      <List listStyle={ListType.Bullets} addClass={styles['no-photos-uploaded-list']}>
        {labels.steps.map((step, index) => (
          <ListItem addClass={styles['no-photos-uploaded-step']} key={index}>
            <Typography color={BrandColors.MediumGray} variant={TypographyVariants.Body}>
              {step}
            </Typography>
          </ListItem>
        ))}
      </List>
    </button>
  );
};
