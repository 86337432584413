import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { useAnalyticsContext } from '../../../context/analytics-context';
import { setIsToasterOpen, useAppContext } from '../../../context/app-context';
import { useProjectSummary, useSaveProject } from '../../../hooks';
import { Drawer } from '../drawer/drawer';
import { DrawerPositions } from '../drawer/drawer-types';
import { HeaderContent } from './fragments/header-content';
import { SummaryFooter } from './fragments/summary-footer';
import { SummaryRow } from './fragments/summary-row';
import { MappedPrice, SummaryData, SummaryDrawerProps, SummaryOption } from './summary-drawer-types';
import { getMappedEndData, getMappedOptions, getMappedRowData, getPricesMap } from './summary-drawer-utils';
import styles from './summary-drawer.module.scss';

export const SummaryDrawer = ({
  isOpen,
  quantity,
  onUpdateQuantity,
  onClose,
  isSavingQuantity,
  register,
}: SummaryDrawerProps): React.ReactElement => {
  const [rowData, setRowData] = useState<SummaryData[]>();
  const [endData, setEndData] = useState<SummaryData>();
  const [options, setOptions] = useState<SummaryOption[]>();
  const [pricesMap, setPricesMap] = useState<MappedPrice>();
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const { appDispatch } = useAppContext();
  const { saveDraftName } = useSaveProject();
  const { trackLeaveProjectSummary, trackUpdatedProjectNameSuccess } = useAnalyticsContext();
  const { priceBook, projectName, productName, hasDefaultProjectName } = useProjectSummary();

  const updateTotal = useCallback(
    (selectedQty: string) => {
      const newPrice = pricesMap?.[`${selectedQty}`];
      if (newPrice) {
        const { price, quantity } = newPrice;
        const newSubtotal = (price * quantity).toFixed(2);
        setRowData(getMappedRowData(newSubtotal));
        setEndData(getMappedEndData(newSubtotal));
        return;
      }
      // if price is not mapped, we reset qty to 1
      onUpdateQuantity('1');
    },
    [pricesMap, onUpdateQuantity],
  );

  useEffect(() => {
    if (!pricesMap) {
      return;
    }
    updateTotal(quantity);
  }, [updateTotal, quantity, pricesMap]);

  useEffect(() => {
    if (priceBook) {
      setOptions(getMappedOptions(priceBook));
      setPricesMap(getPricesMap(priceBook));
    }
  }, [priceBook]);

  const handleOnClose = (ev?: Event) => {
    const clickedElement = ev?.target as HTMLElement;
    const eventAction = clickedElement.dataset.testid === 'drawer_veil' ? 'exit' : 'x';
    trackLeaveProjectSummary(eventAction);
    onClose();
  };

  const handleUpdateQuantity = (selectedQuantity: string) => {
    onUpdateQuantity(selectedQuantity);
  };

  const updateDraftName = async (name: string) => {
    trackUpdatedProjectNameSuccess();
    setIsSaving(true);
    await saveDraftName(name, true);
    setIsToasterOpen(appDispatch, {
      title: t('addressView.successToast'),
      children: t('summaryDrawer.draftNameChanged'),
      variant: ToastVariants.Success,
    });
    setIsSaving(false);
  };

  return (
    <>
      {rowData && endData && options && (
        <Drawer
          drawerTestId="project-summary"
          drawerPosition={DrawerPositions.Top}
          closeIconClass={styles['summary-close-icon']}
          onClose={handleOnClose}
          isOpen={isOpen}
          iconButtonTestid="close-summary-button"
          closeIconSize={24}
          addClass={styles['summary-drawer']}
          trapFocus
          headerContent={
            <HeaderContent
              isSaving={isSaving}
              projectName={hasDefaultProjectName ? productName || '' : projectName || ''}
              onUpdateDraftName={updateDraftName}
            />
          }
        >
          <div className={styles['summary-grid']} data-testid="summary-wrapper">
            {rowData.map((row, index) => (
              <SummaryRow
                data={row}
                options={options}
                onSelect={handleUpdateQuantity}
                isSelectDisabled={isSavingQuantity}
                key={index}
                selectedOption={quantity}
                register={register}
              />
            ))}
            {endData && <SummaryFooter data={endData} />}
          </div>
        </Drawer>
      )}
    </>
  );
};
