import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { useActiveCanvas } from '../../../hooks';
import { getOrderIndices } from '../../../utils/canvas-utils/get-order-indices';
import { getSelectableObjectsIndices } from '../../../utils/canvas-utils/get-selectable-objects-indices';
import { Drawer } from '../drawer/drawer';
import { DrawerPositions } from '../drawer/drawer-types';
import { FooterContent } from './fragments/footer-content';
import { HeaderContent } from './fragments/header-content';
import { OrderDrawerProps } from './order-drawer-types';
import styles from './order-drawer.module.scss';

export const OrderDrawer = ({ isOpen, handleOrderChange, onClose, onSubmit }: OrderDrawerProps) => {
  const { t } = useTranslation();
  const canvas = useActiveCanvas();

  const { currentIndex } = getOrderIndices(canvas?.current);

  // save order of every object in the canvas to revert on cancel
  const originalObjectsOrder = useMemo(() => {
    const { objects: originalObjectsOrder } = getOrderIndices(canvas?.current);
    return originalObjectsOrder;
  }, [canvas?.current]);

  // if the user cancels the changes, revert the order of the objects
  const handleClose = () => {
    originalObjectsOrder.forEach((object, index) => {
      handleOrderChange(index, object);
    });
    onClose();
  };

  // set the new order of the active object on change
  const handleChange = (order: number) => {
    const { objects } = getOrderIndices(canvas?.current);
    const currentIndices = getSelectableObjectsIndices(objects);
    const newOrderIndex = currentIndices[`${order}`];
    handleOrderChange(newOrderIndex);
  };

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <Drawer
      hideCloseButton={true}
      showVeil={false}
      addClass={styles['order-drawer']}
      drawerPosition={DrawerPositions.Bottom}
      isOpen={isOpen}
      onClose={handleClose}
      role="toolbar"
      headerContent={<HeaderContent currentOrder={currentIndex} handleOrderChange={handleChange} />}
      footerContent={<FooterContent onClose={handleClose} onSubmit={handleSubmit} />}
    >
      <Typography variant={TypographyVariants.SRT}>{t('orderDrawer.orderDrawer')}</Typography>
    </Drawer>
  );
};
