import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { IconButton, IconButtonVariants, IconNames } from '@hallmark/web.core.buttons.icon-button';
import { trapFocus as trapFocusHandler } from '@hallmark/web.util.trap-focus';
import { DrawerProps, DrawerPositions } from './drawer-types';
import styles from './drawer.module.scss';
import { getDrawerStyles } from './utils/get-drawer-styles';

export const Drawer = ({
  drawerPosition,
  headerContent,
  isOpen,
  id,
  closeButtonText,
  closeIconSize = 26,
  iconButtonTestid = '',
  drawerTestId = '',
  elevation = 16,
  onClose,
  ariaLabel = '',
  role = 'dialog',
  children,
  addClass = '',
  footerContent,
  showVeil = true,
  hideCloseButton,
  closeIconClass = '',
  trapFocus,
  layoutVariant,
  closeIcon,
  isFooterSticky,
}: DrawerProps): React.ReactElement => {
  const contentClasses = getDrawerStyles({
    drawerPosition,
    layoutVariant,
    showVeil,
    isOpen,
    addClass,
  });

  const overlayClasses = classNames(styles.drawer, isOpen && styles['is-open'], addClass);

  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', close);

    return () => window.removeEventListener('keydown', close);
  }, []);

  useEffect(() => {
    if (trapFocus) {
      trapFocusHandler(drawerRef.current);
    }
  }, [trapFocus, drawerRef.current]);

  return (
    <div className={showVeil ? overlayClasses : ''} data-testid={showVeil ? drawerTestId || 'drawer' : ''}>
      {showVeil && (
        <div
          className={styles.veil}
          data-testid="drawer_veil"
          onClick={onClose}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              onClose();
            }
          }}
        />
      )}
      <div
        id={id}
        data-testid={showVeil ? 'drawer_content' : drawerTestId || 'drawer'}
        className={contentClasses}
        style={{ zIndex: elevation }}
        role={role}
        aria-label={ariaLabel}
        ref={drawerRef}
      >
        <div className={styles.header}>
          {!hideCloseButton && (
            <div
              className={
                drawerPosition === DrawerPositions.Top ? styles['top-drawer-close'] : styles['side-drawer-close']
              }
            >
              <IconButton
                icon={closeIcon ?? IconNames.Menuclose}
                addClass={closeIconClass}
                variant={IconButtonVariants.Simple}
                size={closeIconSize}
                click={onClose}
                testId={iconButtonTestid ?? 'nav-close-icon'}
              >
                {closeButtonText}
              </IconButton>
            </div>
          )}
          {headerContent && <div className={styles.content}>{headerContent}</div>}
        </div>
        <div className={styles.content}>
          <div className={styles['drawer-body']}>{children}</div>
          {footerContent && (
            <div className={`${styles['drawer-footer']} ${isFooterSticky ? styles.sticky : ''}`}>{footerContent}</div>
          )}
        </div>
      </div>
    </div>
  );
};
