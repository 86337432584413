import React from 'react';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { CancelButtonProps } from '../quantity-change-dialog-types';

export const CancelButton = ({ click, label }: CancelButtonProps): React.ReactElement => {
  return (
    <Button click={click} mode={ButtonModes.Secondary} testId="cancel-button">
      {label}
    </Button>
  );
};
