import { CEIconNames, IconNames } from '@hallmark/web.styles.fonts.icomoon';

export enum DrawerLayoutVariants {
  Full = 'layout-full',
  Limited = 'layout-limited',
  Absolute = 'layout-absolute',
}

export enum DrawerPositions {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}

export type GetDrawerStylesProps = {
  drawerPosition: DrawerPositions;
  layoutVariant?: DrawerLayoutVariants;
  showVeil?: boolean;
  isOpen: boolean;
  addClass?: string;
};

export type DrawerProps = {
  /** Sets position from which Drawer will open */
  drawerPosition: DrawerPositions;
  /** Content to appear in drawer header beside close */
  headerContent?: React.ReactNode;
  /** if true, component is open */
  isOpen: boolean;
  /** html id attribute to be assigned to drawer */
  id?: string;
  /** title string to use for accessibility purposes */
  closeButtonText?: string;
  /** size passed to close icon */
  closeIconSize?: number;
  /** Elevation of drawer. Corresponds to z-index of drawer component  */
  elevation?: number;
  /** data-testid for iconButton */
  iconButtonTestid?: string;
  /** The element's role, like role="button". Useful for accessibility */
  role?: string;
  /** aria-label property to associate with drawer */
  ariaLabel?: string;
  /** data-testid for drawer */
  drawerTestId?: string;
  /** Function to fire when modal closes */
  onClose: () => void;
  /** Component Children */
  children: React.ReactNode;
  /** Class to append to Drawer component */
  addClass?: string;
  /** Content to appear in the drawer footer */
  footerContent?: React.ReactNode;
  /** Flag to show Drawer Viel */
  showVeil?: boolean;
  /** send true to hide the X close button */
  hideCloseButton?: boolean;
  /** Optional class to pass to close icon */
  closeIconClass?: string;
  /** If true, the focusable elements will be trapped inside the drawer */
  trapFocus?: boolean;
  /** Specifies the drawer ce variant layout, which may alter its styling and behavior */
  layoutVariant?: DrawerLayoutVariants;
  /*  Choose icon for close icon */
  closeIcon?: CEIconNames | IconNames;
  /** Indicates whether the footer should be sticky (optional). */
  isFooterSticky?: boolean;
};
