import { RangeVariants } from '@hallmark/web.core.forms.range';
import { SharedDrawerProps } from '../../../global-types/drawer';

export enum RangeValuePosition {
  Top = 'Top',
  Bottom = 'Bottom',
}

export interface SizeDrawerProps extends SharedDrawerProps {
  /** Current size value */
  sizeValue: string;
  /** Optional onChange handler. Callback that fires when the range value changes */
  sizeOnChange?: (value: string) => unknown;
  /** Label left for range */
  labelRangeLeft?: string;
  /** Label right for range */
  labelRangeRight?: string;
  /** Label middle or main for range */
  labelRangeMiddle?: string;
  /** Range variat for Range component */
  rangeVariant?: RangeVariants;
  /** Range value position */
  rangeValuePosition?: RangeValuePosition;
}
