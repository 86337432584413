import React, { useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { fabric } from 'fabric';
import type { Canvas } from 'fabric/fabric-impl';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { Icon, IconNames } from '@hallmark/web.core.display.icon';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import {
  DELETE_ICON_TOP_OFFSET,
  DELETE_ICON_TOP_OFFSET_LANDSCAPE,
  DELETE_ICON_TOP_OFFSET_PORTRAIT,
  TEXT_BOX_WIDTH_OFFSET_LANDSCAPE,
  TEXT_BOX_WIDTH_OFFSET_PORTRAIT,
} from '../../constants/user-zone-textbox-offsets';
import { useAnalyticsContext } from '../../context/analytics-context';
import { useAppContext } from '../../context/app-context/app-context';
import {
  setIsImageUploadDrawerOpen,
  setIsTextDrawerOpen,
  setIsToasterOpen,
  setIsWamDrawerOpen,
} from '../../context/app-context/app-context-actions';
import { useCardContext } from '../../context/card-context';
import { useCropContext } from '../../context/crop-context';
import { useInitializationDataContext } from '../../context/data-context';
import { useActiveCanvas, useCardFaceIsEditable, useFeatureFlags, useIsPodProductCode } from '../../hooks';
import { ActionType, useDatadog } from '../../hooks/use-datadog';
import { useIsOneToMany } from '../../hooks/use-is-one-to-many';
import { config } from '../../regional-config';
import colorsList from '../../styles/util.scss';
import { CanvasDataTypes, getGroupedItemByName, handleTemporaryTextEditor, helperSettingsConfig } from '../../utils';
import { addUserTextHandlers, getObjectsByType, hasAvailablePhotoZones, photoZonesAreFilled } from '../../utils/canvas';
import { getCardFaceClipPath } from '../../utils/canvas-utils/get-card-face-clip-path';
import { convertPointToPixel } from '../../utils/fonts';
import { parseTextAction } from '../../utils/parse-text-action';
import { getFontidByUrlName } from '../../utils/utility/get-fontid-by-url-name';
import { getUserZoneTextboxOffsets } from '../../utils/utility/get-user-zone-textbox-offsets';
import { getTextSettings, removeEditableAreaButtons } from '../card-editor/utils';
import { useShowToolbar } from './hooks/use-show-toolbar';
import { ToolbarProps } from './toolbar-types';
import styles from './toolbar.module.scss';

export const Toolbar = ({ addClass, onToolbarItemClick, checkOnCanvas = true }: ToolbarProps) => {
  const { appDispatch } = useAppContext();
  const { cardState } = useCardContext();
  const { updateEditFormats, firstElementSelected, trackPhotoUploadMethod } = useAnalyticsContext();
  const { initializedDataState } = useInitializationDataContext();
  const { isUK } = initializedDataState;
  const { isCropping } = useCropContext();
  const { scalingFactor } = helperSettingsConfig;
  const projectTypeCode = initializedDataState.data?.project_type_code;
  const { t } = useTranslation();
  const { DISPLAY_ZOOM_DEV, SAS_DYNAMIC_BUTTONS } = useFeatureFlags();
  const canvas = useActiveCanvas();
  const isPodProductCode = useIsPodProductCode();
  const { canAddText, type: faceType, isEditable, canAddPhoto } = useCardFaceIsEditable();
  const showToolbar = useShowToolbar();
  const isOneToMany = useIsOneToMany();
  const classes = classNames(styles.toolbar, addClass);
  const { features } = config;
  const currentCardFace = cardState.cardFacesList[cardState.activeCardIndex];
  const shouldShowPodWam =
    (currentCardFace?.type !== 'front' && features?.isPodWamEnabledOnlyInside) || features?.isPodWamEnabled;

  const orientation = cardState.cardFormat;
  const isPortrait = orientation === 'portrait';
  const isLandscape = orientation === 'landscape';

  const { textAction } = useDatadog();

  const openNonEditableToast = useCallback(
    () =>
      setIsToasterOpen(appDispatch, {
        title: '',
        children: t('nonEditableMessage.message', { face: faceType }),
        variant: ToastVariants.Error,
      }),
    [t, faceType],
  );
  const openCoverIsFullToast = useCallback(
    () =>
      setIsToasterOpen(appDispatch, {
        title: '',
        children: t('nonEditableMessage.coverIsFull'),
        variant: ToastVariants.Error,
      }),
    [],
  );

  const handleTextClick = () => {
    if (!canAddText) {
      openNonEditableToast();
      return;
    }

    if (canvas && canvas.current) {
      const defaultTextAreaSettings = { ...initializedDataState.data?.variables.template_data.DefaultTextArea };
      const INITIAL_TEXT_MESSAGE = defaultTextAreaSettings?.Text || `${t('cardEditor.userTextDefaultMessage')}`;
      const textBoxes = canvas.current as fabric.Canvas;
      const cardFaceClipPath = getCardFaceClipPath(cardState.cardFacesList[cardState.activeCardIndex], 0);
      const lastTextBox = getObjectsByType(textBoxes, CanvasDataTypes.UserText).at(-1) as fabric.Textbox;
      const canvasWidth = canvas.current.getWidth();
      const canvasZoom = canvas.current.getZoom();
      const activeCardFace = cardState.cardFacesList[`${cardState.activeCardIndex}`] ?? {};
      const cardFaceType = activeCardFace.type?.split('-')[0];
      if (onToolbarItemClick) {
        onToolbarItemClick('text');
      }
      if (lastTextBox?.text === INITIAL_TEXT_MESSAGE) {
        canvas.current.setActiveObject(lastTextBox);
        canvas.current.renderAll();
        textAction(ActionType.ADD_TEXT, parseTextAction(lastTextBox));
        return;
      }
      const defaultFallbackColor = isUK ? colorsList[BrandColors.CharcoalGrey] : colorsList[BrandColors.Black];
      const defaultColor = defaultTextAreaSettings?.TextColor || defaultFallbackColor;
      const defaultFontSize = convertPointToPixel(
        initializedDataState.data?.variables.template_data.DefaultTextArea.FontSize || 50,
      );
      defaultTextAreaSettings.FontFamilyId = getFontidByUrlName(
        defaultTextAreaSettings?.FontFamily || '',
        initializedDataState.data?.font_collection.fonts,
      );
      const defaultTextSettings = getTextSettings({
        cardState,
        color: defaultColor,
        defaultFontSize,
        isUK,
        defaultOptions: defaultTextAreaSettings,
        name: CanvasDataTypes.UserText,
      });

      // default to left 52 for portrait
      defaultTextSettings.left = 52;
      if (isLandscape) {
        defaultTextSettings.left = 65;
      }

      // TODO: all this options should be a defaultTextOptions config file
      const width =
        isLandscape || cardFaceType === 'front' || cardFaceType === 'back'
          ? canvasWidth / canvasZoom - 2 * (20 * scalingFactor * 2)
          : canvasWidth / (2 * canvasZoom) - 2 * (20 * scalingFactor * 2);
      const textElement = new fabric.Textbox(INITIAL_TEXT_MESSAGE, {
        top: isPortrait ? DELETE_ICON_TOP_OFFSET_PORTRAIT : DELETE_ICON_TOP_OFFSET_LANDSCAPE,
        width: isPortrait ? width - TEXT_BOX_WIDTH_OFFSET_PORTRAIT : width - TEXT_BOX_WIDTH_OFFSET_LANDSCAPE,
        height: 100,
        hasControls: true,
        borderColor: isUK ? '#333333' : '#604099',
        ...defaultTextSettings,
      });
      textElement.set({ data: { type: CanvasDataTypes.UserText } });
      if (cardFaceClipPath) {
        const { TOP_OFFSET, LEFT_OFFSET, RIGHT_OFFSET } = getUserZoneTextboxOffsets(cardState.cardFormat);
        textElement.set({
          top: cardFaceClipPath.top + DELETE_ICON_TOP_OFFSET + TOP_OFFSET,
          left: cardFaceClipPath.left + LEFT_OFFSET,
          width: cardFaceClipPath.width - (LEFT_OFFSET + RIGHT_OFFSET),
          textAlign: 'center',
        });
        textElement.clipPath = cardFaceClipPath;
      }

      addUserTextHandlers(textElement, INITIAL_TEXT_MESSAGE, defaultColor, canvas.current);
      removeEditableAreaButtons(canvas.current);
      canvas.current.add(textElement);
      canvas.current.setActiveObject(textElement);
    }

    setIsTextDrawerOpen(appDispatch);
    updateEditFormats({ eventAction: !firstElementSelected ? 'start' : 'add' });
  };

  const handleImageClick = () => {
    trackPhotoUploadMethod('photo object');
    const currentCanvas = canvas?.current as Canvas;
    // We need to check this first because some cards have photo-zones that can be filled
    //even when the isEditable and canAddPhoto flags are set to false
    if (faceType === 'front' && checkOnCanvas && hasAvailablePhotoZones(currentCanvas)) {
      return openPhotoUploadDrawer();
    }

    if (faceType === 'front' && photoZonesAreFilled(currentCanvas)) {
      return openCoverIsFullToast();
    }

    if (!isEditable || !canAddPhoto) {
      openNonEditableToast();
      return;
    }

    openPhotoUploadDrawer();
  };

  const openPhotoUploadDrawer = () => {
    if (onToolbarItemClick) {
      onToolbarItemClick('photo');
    }
    setIsImageUploadDrawerOpen(appDispatch);
  };

  const handleWriteAMessageClick = () => {
    if ((!canAddPhoto || !canAddText) && isPodProductCode) {
      openNonEditableToast();
      return;
    }
    if (onToolbarItemClick) {
      onToolbarItemClick('message');
    }
    setIsWamDrawerOpen(appDispatch);
  };

  const handleTypeAMessageClick = () => {
    if (canvas?.current) {
      handleTemporaryTextEditor(canvas.current, DISPLAY_ZOOM_DEV, null, SAS_DYNAMIC_BUTTONS);
      const activeObject = canvas?.current?.getActiveObject();
      const originalTextbox =
        activeObject?.type === 'group'
          ? (getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, activeObject as fabric.Group) as fabric.Text)
          : null;
      const isTamEdited = (originalTextbox as fabric.Text)?.text?.length;
      updateEditFormats({ eventAction: isTamEdited ? 'format' : 'add' });
      if (!firstElementSelected.current) {
        updateEditFormats({ eventAction: 'start' });
        firstElementSelected.current = true;
      }
    }
  };

  const buttonProps = {
    P: [
      {
        iconName: IconNames.PhotoslotBold,
        iconLabel: `${t('toolbar.photo')}`,
        testId: 'add-photo-button',
        onClick: handleImageClick,
      },
      {
        iconName: IconNames.TextboxBold,
        iconLabel: `${t('toolbar.text')}`,
        testId: 'add-text-button',
        onClick: handleTextClick,
      },
    ],
    D: [
      {
        iconName: IconNames.PhotoslotBold,
        iconLabel: `${t('toolbar.photo')}`,
        testId: 'add-photo-button',
        onClick: handleImageClick,
      },
      {
        iconName: IconNames.TextboxBold,
        iconLabel: `${t('toolbar.text')}`,
        testId: 'add-text-button',
        onClick: handleTextClick,
      },
    ],
    S: [
      {
        iconName: IconNames.HallmarkSignSendBold,
        iconLabel: `${t('toolbar.writeAMessage')}`,
        testId: 'write-a-message-button',
        onClick: handleWriteAMessageClick,
      },
      {
        iconName: IconNames.HallmarkTypeAMessage,
        iconLabel: `${t('toolbar.typeAMessage')}`,
        testId: 'type-a-message-button',
        onClick: handleTypeAMessageClick,
      },
    ],
  };

  // POD WAM button enabled/disabled based on regional config
  // POD WAM disabled for 1:many cards
  if (shouldShowPodWam && !isOneToMany) {
    buttonProps['P'].push({
      iconName: IconNames.HallmarkSignSendBold,
      iconLabel: `${t('toolbar.writeAMessage')}`,
      testId: 'write-a-message-button',
      onClick: handleWriteAMessageClick,
    });
  }

  return (
    <div id="Toolbar" data-testid="toolbar" className={classes}>
      <ul className={styles.list}>
        {showToolbar &&
          projectTypeCode &&
          !isCropping &&
          buttonProps[`${projectTypeCode}`].map((item, index) => (
            <li key={`list-item-${index}`}>
              <Button
                click={item.onClick}
                data-testid={item.testId}
                testId={item.testId}
                tabIndex={0}
                mode={ButtonModes.Icon}
                addClass={styles['toolbar-button']}
              >
                <Icon
                  name={item.iconName}
                  size={!isMobile ? 40 : 24}
                  addClass={styles.tab}
                  color={BrandColors.White}
                  testId={'textbox-bold'}
                />
                <Typography variant={TypographyVariants.CtaTertiary} addClass={styles.label}>
                  {item.iconLabel}
                </Typography>
              </Button>
            </li>
          ))}
      </ul>
    </div>
  );
};
