import React, { SyntheticEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ButtonGroupProps, ButtonGroupVariant, GroupRole } from './button-group-types';
import styles from './button-group.module.scss';

export const ButtonGroup = ({
  children,
  activeClassOverride,
  defaultClickedIndex,
  onIndexChange,
  addClass = '',
  groupRole = 'listbox',
  ariaLabel,
  variant = ButtonGroupVariant.Primary,
}: ButtonGroupProps): React.ReactElement | null => {
  const [clicked, setClicked] = useState<number | undefined>(defaultClickedIndex);

  const activeClass = activeClassOverride || styles.active;
  const wrapperClasses = classNames(
    styles['button-group-wrapper'],
    { [styles[variant as ButtonGroupVariant]]: variant },
    addClass,
  );
  const buttonRoles: Record<GroupRole, 'option' | 'tab'> = {
    listbox: 'option',
    tablist: 'tab',
  };

  useEffect(() => {
    if (defaultClickedIndex !== undefined) {
      setClicked(defaultClickedIndex);
    }
  }, [defaultClickedIndex]);

  return (
    <div className={wrapperClasses} role={groupRole} aria-label={ariaLabel}>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          click: (e: SyntheticEvent) => {
            setClicked(index);
            if (child.props.click) {
              child.props.click(e);
            }
            if (onIndexChange) {
              onIndexChange(index);
            }
          },
          addClass: `${child.props.addClass} ${
            clicked === index ? classNames(styles.button, activeClass) : styles.button
          }`,
          role: buttonRoles[groupRole || 'listbox'],
          ariaSelected: clicked === index,
        }),
      )}
    </div>
  );
};
