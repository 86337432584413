import React, { useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { Range, RangeVariants } from '@hallmark/web.core.forms.range';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { CEBrandColors } from '@hallmark/web.styles.colors';
import { Drawer } from '../drawer/drawer';
import { DrawerPositions } from '../drawer/drawer-types';
import { SizeDrawerProps, RangeValuePosition } from './size-drawer-types';
import styles from './size-drawer.module.scss';

export const SizeDrawer = ({
  onClose,
  isOpen,
  handleSubmit,
  sizeValue,
  sizeOnChange,
  addClass = '',
  actionButtonClass,
  cancelButtonClass,
  hasActionButton = true,
  hasCancelButton = true,
  actionButtonLabel = 'Apply',
  cancelButtonLabel = 'Cancel',
  labelRangeLeft,
  labelRangeRight,
  labelRangeMiddle,
  layoutVariant,
  actionButtonMode = ButtonModes.Primary,
  cancelButtonMode = ButtonModes.Secondary,
  rangeVariant = RangeVariants.Primary,
  rangeValuePosition = RangeValuePosition.Bottom,
  showVeil,
}: SizeDrawerProps): React.ReactElement => {
  const classes = classNames(styles['size-drawer'], addClass);

  const { register, setValue } = useForm({
    defaultValues: {
      sizeRange: sizeValue,
    },
  });

  const [rangeValue, setRangeValue] = useState(sizeValue);

  const onChange = (currentRangeValue: number) => {
    setRangeValue(currentRangeValue.toString());
    if (sizeOnChange) {
      sizeOnChange(currentRangeValue.toString());
    }
  };

  useEffect(() => {
    setRangeValue(sizeValue);
    setValue('sizeRange', sizeValue);
  }, [sizeValue]);

  const HeaderContent = useMemo((): React.ReactElement => {
    return (
      <div className={classNames(styles['header-wrapper'])}>
        {rangeValuePosition && rangeValuePosition === 'Top' && (
          <div className={styles['size-range-label']}>
            <Typography
              color={CEBrandColors.Purple}
              variant={TypographyVariants.BodyBold}
            >{`${rangeValue}`}</Typography>
          </div>
        )}
        <Range
          domId={'size'}
          label={labelRangeMiddle}
          testId="range-testid"
          min={5}
          max={60}
          tickCount={0}
          onChange={onChange}
          register={register('sizeRange')}
          leftLabel={labelRangeLeft}
          rightLabel={labelRangeRight}
          variant={rangeVariant}
          initialValue={Number(sizeValue)}
        />
        {rangeValuePosition && rangeValuePosition === 'Bottom' && (
          <Typography variant={TypographyVariants.Body}>{`${rangeValue}`}</Typography>
        )}
      </div>
    );
  }, [rangeValue]);

  const FooterContent = (): React.ReactElement => {
    return (
      <div className={styles['footer-wrapper']}>
        {hasCancelButton && (
          <Button
            addClass={classNames(styles['footer-buttons'], cancelButtonClass)}
            click={onClose}
            mode={cancelButtonMode}
          >
            {cancelButtonLabel}
          </Button>
        )}
        {hasActionButton && handleSubmit && (
          <Button
            addClass={classNames(styles['footer-buttons'], actionButtonClass)}
            click={handleSubmit}
            mode={actionButtonMode}
          >
            {actionButtonLabel}
          </Button>
        )}
      </div>
    );
  };

  return (
    <Drawer
      addClass={classes}
      hideCloseButton={true}
      drawerPosition={DrawerPositions.Bottom}
      onClose={onClose}
      isOpen={isOpen}
      headerContent={HeaderContent}
      showVeil={showVeil}
      role="toolbar"
      ariaLabel="Size Drawer"
      layoutVariant={layoutVariant}
    >
      <FooterContent />
      <Typography variant={TypographyVariants.SRT}>Size Drawer</Typography>
    </Drawer>
  );
};
