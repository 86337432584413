import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { BrandColors } from '@hallmark/web.styles.colors';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useAnalyticsContext } from '../../context/analytics-context';
import { setIsSaving, useAppContext } from '../../context/app-context';
import { useCropContext } from '../../context/crop-context';
import { useInitializationDataContext } from '../../context/data-context';
import { useProjectSummary, useQueryParams, useSaveProject } from '../../hooks';
import { savePersonalization } from '../../utils/save-project';
import { LoadingButton } from '../loading-button/loading-button';
import styles from './save-project-link.module.scss';

export const SaveProjectLink = () => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const { trackSaveLink } = useAnalyticsContext();
  const { appState, appDispatch } = useAppContext();
  const { productQuantity, isSaving } = appState;
  const { pathname } = useLocation();
  const hasUpdatedDraftName = useRef(false);
  const { t } = useTranslation();
  const { saveDraftName, saveProject } = useSaveProject();
  const { hasDefaultProjectName } = useProjectSummary();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const isEditorView = pathname.includes('edit');
  const { isSavingCroppedImage } = useCropContext();
  const handleClick = async () => {
    trackSaveLink();
    // We won't show the dialog if the user already updated the draft name
    if (hasUpdatedDraftName.current || !hasDefaultProjectName) {
      setIsSaving(appDispatch, true);
      const saveRequests: [Promise<void> | undefined] = [
        savePersonalization(
          { shouldRestoreCanvas: true, showLoadingScreen: false, generateFrontPreview: false },
          isEditorView,
          saveProject,
        ),
      ];
      if (Number(productQuantity) !== initializedData?.purchase_quantity && initializedData?.name) {
        saveRequests.push(saveDraftName(initializedData.name, true));
      }
      await Promise.all(saveRequests);

      setIsSaving(appDispatch, false);
      return;
    }
    queryParams.append('saveProjectDialog', 'true');
    navigate({ pathname, search: queryParams.toString() }, { replace: true });
  };

  return (
    <LoadingButton
      testId="save-project-loading-button"
      addClass={styles['save-project-link']}
      click={handleClick}
      isLoading={isSaving || isSavingCroppedImage}
      loadingColor={BrandColors.Disabled}
      defaultColor={BrandColors.Blue}
      loadingIconName={IconNames.SyncSyncInactive}
      isIconSpinning={true}
      loadingText={`${t('header.saving')}`}
      defaultText={`${t('header.save')}`}
    />
  );
};
