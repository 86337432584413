import type { Canvas } from 'fabric/fabric-impl';
import { CardFace, ImageResponse, InitializationData, RegionalCodesList } from '../global-types';
import { ErrorResponse } from '../global-types/services';
import { OnSystemError } from '../hooks/use-system-error-handling';
import { CanvasDataTypes } from '../utils';
import { TransformedPersonalizationData } from './helper-settings';
import { addIdToTexts } from './utility/assign-ids-to-texts';

/**
 *
 * @param word String that will get capitalized
 * @returns Capitalized string
 */
export const capitalize = (word: string) => word[0].toUpperCase() + word.slice(1);

/**
 * Returns a new object without the key passed by parameter. It will delete the key of nested arrays and objects
 *
 * @param obj Object from which key will be removed
 * @param key Name of the key to be removed
 * @returns New object not containing the passed key.
 */
export const removeObjectKey = <T extends object, K extends keyof T>(obj: T, key: K): Omit<T, K> => {
  return Object.keys(obj).reduce((result, currentKey) => {
    if (currentKey === key) {
      return result;
    }

    const value = obj[`${currentKey}`];
    if (Array.isArray(value)) {
      // Recursively remove the key from each element of the array
      result[`${currentKey}`] = value.map((item) => removeObjectKey(item, key));
    } else if (typeof value === 'object' && value !== null) {
      // Recursively remove the key from nested objects
      result[`${currentKey}`] = removeObjectKey(value, key);
    } else {
      result[`${currentKey}`] = value;
    }

    return result;
  }, {} as Omit<T, K>);
};

/**
 * Checks a string to see if it returns a 404 as a URL and the function returns a true/false
 *
 * @param url string that represents a url
 * @returns True/False boolean
 */
const isUrlValid = (url: string) => {
  const http = new XMLHttpRequest();
  http.open('HEAD', url, false);
  http.send();

  return http.status < 400;
};

/**
 * Checks an array of CardFaces' background images and displays an error message if any return an error status
 *
 * @param faces Array of CardFaces from the initialize response
 * @param onSystemError hook function that handles errors
 * @param errorMessage string from translations file for the error to be thrown
 * @returns function that displays an error message if any images are found to be erroring
 */
export const validateImages = (faces: CardFace[], onSystemError: OnSystemError, errorMessage: string) => {
  faces.forEach((face) => {
    try {
      if (!isUrlValid(face.BackgroundUrl)) throw errorMessage;
    } catch (errors) {
      onSystemError(errors as ErrorResponse);
    }
  });
};

/**
 * Checks background image SRCs to ensure none are blank or include ?w=0 param or if canvasJSON is null,
 * which has occurred in the past due to CORS errors during card editing, or empty canvasJson in UK
 * and resulted in bad JSON in placed orders
 *
 * This function needs to assume data is populated by default and return false to trigger an error.
 * The inverse causes an error to show by default and prevents navigation to the address view.
 *
 * @param data Array of TransformedPersonalizationData
 * @returns true if image SRCs or canvasJSON are populated, false if image SRCs are null or include ?w=0 param
 */
export const isDataPopulated = (data: TransformedPersonalizationData[]) => {
  data.forEach((face) => {
    if (
      !face.PrintJson?.backgroundImage?.src ||
      face.PrintJson?.backgroundImage?.src?.includes('?w=0') ||
      !face.CanvasJson
    ) {
      return false;
    }
  });
  return true;
};

export const formatResponseData = (data: InitializationData) => {
  if (data.is_digital_fulfillment) {
    data.project_type_code = 'D';
    data.name = 'Digital Greetings';
  }

  const dataWithTextIds = addIdToTexts(data);

  return dataWithTextIds;
};

/**
 * Get all or a single search param from the URL
 * @param param param to get from url
 * @returns URLSeachParams | string
 */
export const getUrlSearchParam = (param?: string) => {
  const { search } = window.location;
  const urlParams = new URLSearchParams(search);

  if (!param) {
    return urlParams;
  } else {
    return urlParams.get(`${param}`);
  }
};

/**
 * @param regions the current region we are using. Ex: us, uk, ce, all
 * @returns a boolean value confirming that the region is allowed to be used, based on the RegionalCodesList
 */
export const isRegion = (region = ''): boolean | null => {
  return Object.values(RegionalCodesList).some((regionalCode) => region.includes(regionalCode));
};

/**
 * Checks if the current locale matches any of the provided region codes.
 *
 * @param {RegionalCodesList[]} regions Array of region codes to check against the current locale.
 * @returns {boolean} True if the current locale matches any of the provided region codes.
 */
export const isAnyOfRegions = (regions: RegionalCodesList[]): boolean => {
  const locale: string = process.env.REACT_APP_THEME || '';
  return regions.some((regionCode) => locale === regionCode);
};

/** Checks if the toolbar should be visible based on the active canvas and the current mode.
 * @param activeCanvas The active canvas ref
 * @param isSignAndSend Is Sign And Send Card
 * @param hideToolbar Optional flag to directly hide the toolbar (ie. hide based on a feature flag)
 * @returns boolean
 */
export const isToolbarVisible = (
  activeCanvas: React.RefObject<Canvas> | undefined,
  isSignAndSend: boolean,
  sasDynamicButtons = false,
): boolean => {
  if (!activeCanvas?.current || (isSignAndSend && sasDynamicButtons)) {
    return false;
  }

  if (!isSignAndSend) {
    return true;
  }

  return Boolean(activeCanvas.current._objects.find((obj) => obj?.name?.includes(CanvasDataTypes.PhotoTextZone)));
};

export const highlightMustEditText = (canvas: fabric.Canvas | null) => {
  if (canvas) {
    canvas.forEachObject((obj: any) => {
      if (obj.MustEditTextArea && obj.data.type === CanvasDataTypes.EditableText && obj.data.isEdited === false) {
        const ctx = obj.canvas.contextContainer;
        ctx.strokeStyle = '#FF0000';
        ctx.setLineDash([5, 5]);
        const bound = obj.getBoundingRect();
        ctx.strokeRect(bound.left + 0.5, bound.top + 0.5, bound.width, bound.height);
      }
    });
  }
};

export const getMonolithUrl: () => string = () => {
  const parts = window.location.hostname.split('.');
  // window.location.hostmane.includes dev, change it to stage
  // uk app lives at a subdomain 'cards', so monolithUrl needs this removed
  const baseDomain = parts.filter((part) => part !== 'cards');
  const domain = baseDomain.join('.');
  const monolithUrl = `${window.location.protocol}//${domain}`;

  return typeof window !== 'undefined' ? monolithUrl : '';
};

export const getImagesFromFile = (files: FileList, photoTrayImages: ImageResponse[]) => {
  const newPhotos: ImageResponse[] = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[`${i}`];
    const url = URL.createObjectURL(file);
    newPhotos.push({
      account_id: String(photoTrayImages.length + i),
      created_at: new Date().toISOString(),
      image_url: url,
      is_handwriting_image: false,
      photo_tray_image_id: String(photoTrayImages.length + i),
      image_id: String(photoTrayImages.length + i),
    });
  }
  return newPhotos;
};
