import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@hallmark/web.core.buttons.button';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { useAnalyticsContext } from '../../../context/analytics-context';
import { useAppContext } from '../../../context/app-context';
import {
  hideLoadingScreen,
  setIsToasterOpen,
  showLoadingScreen,
} from '../../../context/app-context/app-context-actions';
import { useCardContext } from '../../../context/card-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { CardType } from '../../../global-types';
import { useActiveCanvas, useFeatureFlags, useIsPodProductCode } from '../../../hooks';
import { useDatadog } from '../../../hooks/use-datadog';
import { imageConverter } from '../../../utils/utility/image-converter';
import uploadImage, { MAX_FILE_SIZE } from '../../../utils/utility/image-upload';
import { InvalidImageFormatError } from '../../../utils/utility/validate-uploaded-image-format';
import { addWamImageToCanvas } from '../../card-editor/utils';
import { Drawer } from '../drawer/drawer';
import { DrawerPositions } from '../drawer/drawer-types';
import { InstructionStep } from './fragments/instruction-step';
import { StepDataProp, WamInstructionsDrawerProps } from './wam-instructions-drawer-types';
import styles from './wam-instructions-drawer.module.scss';

export const WamInstructionsDrawer = ({
  onClose,
  isOpen,
  onImageSelect,
  onCrop,
}: WamInstructionsDrawerProps): React.ReactElement => {
  const { t } = useTranslation();
  const datadog = useDatadog();
  const { cardState } = useCardContext();
  const { trackWamPhotoUpload } = useAnalyticsContext();

  const steps: StepDataProp[] = [
    {
      title: `${t('writeMessage.stepOneTitle')}`,
      text: `${t('writeMessage.stepOneText')}`,
      imageUrl:
        'https://images.ctfassets.net/ighyyu7ii7g1/600q37bg0hqGvDCJ4Sp3B/ea827a0d85394b05fdc36c65d0e7262e/MGE-sign-and-send-step-01.jpg',
    },
    {
      title: `${t('writeMessage.stepTwoTitle')}`,
      text: `${t('writeMessage.stepTwoText')}`,
      imageUrl:
        'https://images.ctfassets.net/ighyyu7ii7g1/7k3pehUQwZpz7PTiQ2Cwj7/2f982b3e5f8d2e25e1bdf5a7cdffa3f4/MGE-sign-and-send-step-02.jpg',
    },
    {
      title: `${t('writeMessage.stepThreeTitle')}`,
      text: `${t('writeMessage.stepThreeText')}`,
      imageUrl:
        'https://images.ctfassets.net/ighyyu7ii7g1/5se61qUw51OVhvzSN9weXg/cd38161c01ae0960887da595e402eb22/MGE-sign-and-send-step-03.jpg',
    },
  ];

  const inputRef = useRef<HTMLInputElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const canvas = useActiveCanvas();
  const { appDispatch } = useAppContext();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const isPodProductCode = useIsPodProductCode();
  const { SAS_DYNAMIC_BUTTONS } = useFeatureFlags();
  const errorMessage = t('writeMessage.sizeErrorMessage');

  const handleImageUpload = async (event: React.FormEvent<HTMLInputElement>): Promise<void> => {
    const uploadedFiles = (event.target as HTMLInputElement).files;
    if (!uploadedFiles || !initializedData) {
      throw Error('Required data undefined when uploading hadnwriting');
    }

    const fileInfo = Array.from(uploadedFiles ?? []).map((uploadedFile) => {
      return {
        name: uploadedFile.name,
        lastModified: uploadedFile.lastModified,
        size: uploadedFile.size,
        type: uploadedFile.type,
      };
    });

    datadog.addAction('upload-handwriting-photo', {
      files: fileInfo,
      totalUploadedPhotos: fileInfo.length,
      samplePhotoSize: fileInfo[0]?.size,
      samplePhotoType: fileInfo[0]?.type,
      cardFace: cardState.cardFacesList[`${cardState.activeCardIndex}`]?.type ?? null,
      zoneId: cardState.selectedPhotoZone?.id || null,
    });

    const convertedFile = await imageConverter(uploadedFiles[0])();
    const isInvalidSize = convertedFile.size >= MAX_FILE_SIZE;
    if (isInvalidSize) {
      onClose();
      return setIsToasterOpen(appDispatch, {
        variant: ToastVariants.Error,
        title: `${t('writeMessage.sizeTitleError')}`,
        children: errorMessage,
      });
    }

    try {
      showLoadingScreen(appDispatch, 'Uploading your image...');

      const { imageUrl, imageId, cleanUpVersionId } = await uploadImage(convertedFile, initializedData.project_id, {
        cleanUp: true,
        uploadMetadata: {
          is_handwriting_image: `${initializedData?.project_type_code === CardType.SAS}`,
          display_indicator: `${isPodProductCode}`,
        },
        events: {
          onCleanUpStart: () => showLoadingScreen(appDispatch, 'Making it look handwritten...'),
        },
      });

      const imageAddedSuccessfully = await addWamImageToCanvas(
        imageUrl,
        isPodProductCode,
        cardState,
        canvas,
        imageId,
        cleanUpVersionId,
        onImageSelect,
        onCrop,
        SAS_DYNAMIC_BUTTONS,
      );

      if (imageAddedSuccessfully) {
        trackWamPhotoUpload(convertedFile.size.toString());
        datadog.addAction('add-handwriting-to-card-face', {
          handwriting: imageAddedSuccessfully.data,
          cardFace: cardState.cardFacesList[`${cardState.activeCardIndex}`]?.type ?? null,
          zoneId: cardState.selectedPhotoZone?.id || null,
        });
      }

      hideLoadingScreen(appDispatch);
    } catch (e) {
      if (e instanceof InvalidImageFormatError) {
        setIsToasterOpen(appDispatch, {
          variant: ToastVariants.Error,
          title: t('imageUploadDrawer.imageErrorTitle'),
          children: t('imageUploadDrawer.onlyImagesMessage'),
        });
      } else {
        setIsToasterOpen(appDispatch, {
          title: t('writeMessage.errorTitle'),
          children: errorMessage,
          variant: ToastVariants.Error,
        });
      }

      hideLoadingScreen(appDispatch);
    }
  };

  useEffect(() => {
    buttonRef.current?.focus();
  }, [isOpen]);

  const FooterContent = (): React.ReactElement => (
    <>
      <Button
        id="wam-upload-button"
        click={() => {
          inputRef.current?.click();
        }}
        addClass={styles['upload-button']}
        ref={buttonRef}
      >
        {t('writeMessage.footerContent')}
      </Button>
      <input
        type="file"
        aria-labelledby="wam-upload-button"
        data-testid="wam-upload-input"
        ref={inputRef}
        onChange={handleImageUpload}
        style={{ display: 'none' }}
        accept="image/jpeg,image/jpg,image/gif,image/png,image/heic,image/heif,image/webp "
      />
    </>
  );

  return (
    <Drawer
      id="wam-instructions-drawer"
      drawerPosition={DrawerPositions.Left}
      onClose={onClose}
      isOpen={isOpen}
      closeIconSize={24}
      addClass={styles['wam-instructions-drawer']}
      footerContent={<FooterContent />}
      trapFocus
    >
      {steps.map(({ imageUrl, title, text }, index) => (
        <InstructionStep
          key={`wam-instruction-${index + 1}`}
          imageUrl={imageUrl}
          text={text}
          title={title}
          stepNumber={index + 1}
        />
      ))}
    </Drawer>
  );
};
